<template>
  <td class="text-center">
    <q-img
      :src="product_value.Logo"
      spinner-color="white"
      style="min-width: 100px; max-width: 100px"
    />
  </td>
  <td class="text-center" style="white-space: initial">
    {{ product_value.selectielijst }}
  </td>
  <td class="text-center">
    <div style="white-space: initial" v-html="product_value.USP"></div>
    <q-tooltip
      v-if="product_value.thuisvoordeel"
      style="max-width: 10px"
      anchor="top middle"
      self="bottom middle"
      class="bg-primary text-center text-weight-bold"
      :offset="[-5, -5]"
    >
      De klant wordt automatisch<br />
      aangemeld bij thuisvoordel met<br />
      extra voordelige stroomtarieven
    </q-tooltip>
  </td>
  <td class="text-center">
    <q-btn
      size="12px"
      style="width: 120px"
      :color="findSelectedProduct(product_key) ? 'negative' : 'primary'"
      :label="findSelectedProduct(product_key) ? 'Deselecteer' : 'Kiezen'"
      no-caps
      @click="setProduct(product_key)"
    />
  </td>
  <td class="text-right">
    <q-select
      :readonly="readonly"
      label="Incentive"
      transition-show="jump-down"
      transition-hide="jump-down"
      map-options
      outlined
      dense
      option-label="naam"
      option-value="IncentiveId"
      :key="product_key"
      :options-html="true"
      v-show="findSelectedProduct(product_key)"
      :modelValue="selectedProductsMethod(product_key)"
      @update:model-value="
        select = { incentive: $event, id: product_key, product: product_value }
      "
      :options="product_value.incentives"
    />
  </td>
  <td
    style="width: 40px"
    v-show="
      findSelectedProduct(product_key) && findInstallmentAmount(product_key)
    "
  >
    <div class="text-weight-bold">Termijnbedrag:</div>
    <div class="text-weight-bolder text-center" style="color: #0be3ab">
      {{
        new Intl.NumberFormat("nl-NL", {
          style: "currency",
          currency: "EUR",
          maximumFractionDigits: 0,
        }).format(findInstallmentAmount(product_key))
      }}
    </div>
    <div
      :key="selectedPanel"
      v-show="!findVat(product_key)"
      class="text-weight-bolder text-center"
      style="color: #0be3ab"
    >
      excl. btw
    </div>
  </td>
</template>

<script setup>
import { ref, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { date } from "quasar";
import { getStartDateObj } from "@/plugins/start-date.js";
import {
  delete_product,
  set_product,
  set_selected_product,
} from "@/plugins/products.js";

const { formatDate } = date;

//* Props
// eslint-disable-next-line no-undef
defineProps({
  product_value: Object,
  product_key: Number || String,
});

//* VueUse
const $store = useStore();

//* Refs
const selectedPanel = ref("");

//* Computed
const selectedProducts = computed(() => $store.getters.selectedProducts ?? []);
const readonly = computed(
  () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
);
const startDateObj = computed({
  get() {
    return $store.getters.startDateObj ?? {};
  },
  set(newVal) {
    $store.dispatch("setStartDateObj", newVal);
  },
});
const select = computed({
  set(newVal) {
    let campaignId = newVal.product.campaignidno;
    if (
      newVal.incentive.IncentiveId != "Geen" &&
      newVal.incentive.naam != "Geen"
    ) {
      campaignId = newVal.product.campaignidyes;
    } else {
      newVal.incentive.IncentiveId = newVal.product.incentiveidno;
    }
    newVal.product.campaignid = campaignId;

    $store.dispatch("setProjectSpecificValues", {
      values: {
        incentive_titel: newVal.incentive.IncentiveId,
        Incentice_kosten: newVal.incentive.IncentiveKosten,
        Incentive_kosten_Improvers: newVal.incentive.IncentiveKostenImprovers,
        Termijnbedragaanpassen: "Nee",
        Ingangsdatumaanpassen: "Nee",
      },
    });

    startDateObj.value = getStartDateObj();
    setStartDate(formatDate(startDateObj.value.minimalDate, "DD-MM-YYYY"));
    const selected = selectedProducts.value.find(
      (arr) => arr.product_id == newVal.product.productid
    );
    newVal.type_id = selected.type_id;
    set_product(newVal);
    $store.dispatch("send_message", { message: "get_offers", body: newVal });
  },
});

//*  Methods
function setStartDate(startDate) {
  $store.dispatch("setStartDate", startDate);

  $store.dispatch("setProjectSpecificValue", {
    key: "StartDate",
    value: startDate,
  });
}

function setProduct(product_id) {
  if (!findSelectedProduct(product_id)) {
    set_selected_product(product_id);
  } else {
    delete_product(product_id);
  }
  return this.$forceUpdate();
}

function findSelectedProduct(key) {
  return selectedProducts.value.find((arr) => arr.product_id == key);
}

function findInstallmentAmount(key) {
  const product = findSelectedProduct(key);
  if (product && product.offer_translated) {
    const offer = JSON.parse(product.offer_translated);

    return offer._ToAmMonth_;
  }
  return false;
}

function findVat(key) {
  const product = findSelectedProduct(key);
  if (product && product.offer_translated) {
    const offer = JSON.parse(product.offer_translated);

    if (offer._VAT == "true") {
      return true;
    }
  }
  return false;
}

function selectedProductsMethod(key, type) {
  let tmp = findSelectedProduct(key, type);
  if (tmp) {
    return tmp?.incentive?.IncentiveId ?? "";
  }
}

//* Conditions
if (selectedProducts.value) {
  $store.dispatch("send_message", { message: "get_all_offers" });

  $store.dispatch("setProjectSpecificValue", {
    key: "redo_offer",
    value: false,
  });
}
</script>
<style lang="sass"></style>
