<template>
  <q-markup-table flat>
    <thead>
      <tr class="text-weight-bold">
        <th class="text-center" style="font-weight: bold !important">
          Aanbieder
        </th>
        <th class="text-center" style="font-weight: bold !important">
          Product
        </th>
        <th class="text-center" style="font-weight: bold !important">
          Details
        </th>
        <th class="text-center" style="font-weight: bold !important">
          Frequentie
        </th>
        <th class="text-center" style="font-weight: bold !important"></th>
        <th class="text-center" style="font-weight: bold !important"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="product in products"
        :key="product.key + selectedProducts.length"
        :style="
          findSelectedProduct(
            product.key,
            product?.aanbieding_id ?? 0,
            product.type
          )
            ? 'background-color: rgb(240, 255, 240); transition: 0.2s;'
            : 'transition: 0.2s;'
        "
        v-show="showProduct(product, product.type)"
      >
        <energy-row
          v-if="product.type == 'energy'"
          :product_value="product"
          :product_key="product.key"
        />
        <charity-row
          v-else
          :product_value="product"
          :product_key="product.key"
        />
      </tr>
    </tbody>
  </q-markup-table>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import EnergyRow from "./Rows/EnergyRow.vue";
import charityRow from "./Rows/CharityRow.vue";

//* VueUse
const $store = useStore();

//* Refs

//* Computed
const products = computed(() => $store.getters.products.list_products_array);
const selectedProducts = computed(() => $store.getters.selectedProducts ?? []);
const projectSpecific = computed(() => $store.getters.projectSpecific);

//*  Methods
function showProduct(value, type) {
  if (type !== "energy") return true;

  return (
    (value.contract == "Gas en Elektriciteit" &&
      (!projectSpecific.value["MONO"] ||
        (projectSpecific.value["EAN Elektra"] &&
          projectSpecific.value["EAN Gas"]))) ||
    (value.contract == "Elektriciteit" &&
      (projectSpecific.value["MONO"] ||
        (projectSpecific.value["EAN Elektra"] &&
          !projectSpecific.value["EAN Gas"]))) ||
    (value.contract == "Gas" &&
      (projectSpecific.value["MONO"] ||
        (!projectSpecific.value["EAN Elektra"] &&
          projectSpecific.value["EAN Gas"])))
  );
}

function findSelectedProduct(key, aanbieding_id, type) {
  if (type == "energy") {
    return selectedProducts.value.find((arr) => arr.product_id == key);
  } else {
    return selectedProducts.value.find(
      (arr) =>
        arr.product_id == key && arr.product.aanbieding_id == aanbieding_id
    );
  }
}
</script>
<style lang="sass"></style>
